import React from 'react';
import { styled } from '../style';

export const Footer = () => (
  <FooterContainer>
    <p>Henry Mălineanu (1920 - 2000) - compozitor, dirijor, textier şi om de teatru român.</p>
    <p>
      Autor a nenumarate șlagăre de muzică ușoară ("Cum am ajuns să te iubesc ?", "Ce cauți tu în viața mea ?!", "Cele mai frumoase fete", "Dragi mi-s
      cântecele mele !", "Hop și-așa !", "Garofița", "Ce să fac dacă-mi placi ?!", "Mica serenadă", etc.), romanțe ("Nu se poate !", "Aș vrea iar anii
      tinereții !", "Am început să-mbătrânesc" etc), cântece de petrecere ("Astă seară mă fac praf !", "Zi-i una mai săltăreață", "Ia mai toarnă-un
      păhărel !", etc), operetă ("Suflet candriu"), comedii muzicale ("Au fost odată... două orfeline", "Bună seara domnule Wilde"), muzică de teatru
      ("Comedie de modă veche", "Căruța cu paiațe", "Micul infern", etc) si film ("Pantoful Cenușăresei", "Gaudeamus igitur",etc), dirijor la Teatrele
      „Baraseum" (1942-1944), „Gioconda" (1944-1948), director muzical si dirijor la Teatrul de Revista din București (1950-1958), Artist Emerit
      (1962), Laureat al Premiului de Stat (1953).
    </p>
    <p>Copyright © 2015 - Fundaţia Henry Mălineanu</p>
  </FooterContainer>
);

const FooterContainer = styled.footer`
  padding: ${({ theme }) => theme.sizes.xl} ${({ theme }) => theme.sizes.md};
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.fontGray};

  @media (max-width: ${({ theme }) => theme.sizes.phone}) {
    padding-bottom: ${({ theme }) => theme.sizes.xlNum * 2}px;
  }
`;
