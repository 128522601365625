export type SongData = {
  id: number;
  title: string;
  file: string;
  extension?: string;
  color: string;
  lightText?: boolean;
};

export const SONGS: SongData[] = [
  { id: 0, title: 'E de necrezut !', file: 'E de necrezut (Mihaela Oancea)', color: '#049fbb', lightText: true },
  { id: 1, title: 'Cum am ajuns să te iubesc ?!', file: 'Cum am ajuns sa te iubesc (Stefan Banica)', color: '#c2cb00' },
  {
    id: 2,
    title: 'Mi-e dor astă seară de-un chef cum știu eu',
    file: 'Mi-e dor asta seara de-un chef cum stiu eu (Gica Petrescu)',
    color: '#fb7d34',
  },
  { id: 3, title: 'Asta seara ma fac praf', file: 'Asta seara ma fac praf (Stefan Banica)', color: '#ff1703', lightText: true },
  { id: 4, title: 'Adevărata mea dragoste', file: 'Adevarata mea dragoste (Dorina Draghici)', color: '#c24354' },
  { id: 5, title: 'Marinică', file: 'Marinica (Dorina Draghici)', color: '#a1dabc' },
  { id: 6, title: 'Ce cauți tu în viața mea ?', file: 'Ce cauti tu in viata mea (Gica Petrescu)', color: '#6e7a68' },
  { id: 7, title: 'Nu se poate !...', file: 'Nu se poate (Ioana Radu)', color: '#ddd5c3' },
  { id: 8, title: 'Nici-o dragoste nu e ca a noastră', file: 'Nici o dragoste nu e ca a noastra (Constantin Draghici)', color: '#948e8d' },
  { id: 9, title: 'Cele mai frumoase fete', file: 'Cele mai frumoase fete (Nicolae Nitescu)', color: '#772257', lightText: true },
  { id: 10, title: 'Primul nostru tango', file: 'Primul nostru tango (Gica Petrescu)', color: '#e7b900' },
  { id: 11, title: 'Aș vrea iar anii tinereții...', file: 'As vrea iar anii tineretii (Ioana Radu)', color: '#948e8d' },
  { id: 12, title: 'Nimeni', file: 'Nimeni (Doina Badea)', color: '#b32e14' },
  { id: 13, title: 'Dragi mi-s cântecele mele !', file: 'Dragi mi-s cantecele mele (Maria Tanase)', color: '#79738c', lightText: true },
  { id: 14, title: 'Hop și-așa !', file: 'Hop si-asa (Gica Petrescu)', color: '#b8b0a1' },
  { id: 15, title: 'Am început să-mbatrânesc', file: 'Am inceput sa-mbatranesc (Ioana Radu)', color: '#5e471f', lightText: true },
  { id: 16, title: 'Dacă n-oi iubi acum !...', file: 'Daca n-oi iubi acum (Dan Spataru)', color: '#a1dabc' },
  { id: 17, title: 'Garofița', file: 'Garofita (Gica Petrescu)', color: '#e7b900' },
  { id: 18, title: 'Ce să fac dacă-mi placi ?!', file: 'Ce sa fac daca-mi placi (Bianca Ionescu)', color: '#049fbb', lightText: true },
  { id: 19, title: 'O fată mai găsești, dar un prieten, nu !', file: 'O fata mai gasesti (Jean Paunescu)', color: '#fa7d34' },
  { id: 20, title: 'Iubito !', file: 'Iubito (Luigi Ionescu)', color: '#3c6172', lightText: true },
  { id: 21, title: 'Au r’voir, bye-bye, ciao-ciao !', file: 'Au revoir, bye-bye, ciao-ciao (Doina Badea)', color: '#e99809' },
  { id: 22, title: 'Mica serenadă', file: 'Mica serenada (Maria Sereea)', color: '#c24354' },
  { id: 23, title: 'Zi-i una mai săltăreață !', file: 'Zi-i una mai saltareata (Gica Petrescu)', color: '#a1d9bc' },
  { id: 24, title: 'Dacă ajung la inima ta !...', file: 'Dac-ajung la inima ta (Dan Spataru)', color: '#6e7a68', lightText: true },
  { id: 25, title: 'Barcarola', file: 'Barcarola (Doina Badea)', color: '#e7c9a3' },
  { id: 26, title: 'Ia mai toarnă-un păhărel !', file: 'Ia mai toarna un paharel (Gica Petrescu)', color: '#c2ca00' },
  { id: 27, title: 'Pe cine-aș fi iubit ?!... (dacă n-ai fi fost tu ?)', file: 'Pe cine-as fi iubit (Nicolae Nitescu)', color: '#e8c8a3' },
  { id: 28, title: 'Ce bine ne-nțelegem noi doi !', file: 'Ce bine ne-ntelegem noi doi (Bianca Ionescu)', color: '#b32d12' },
  { id: 29, title: 'Că doar n-o să trăiesc cât lumea !', file: 'Ca doar n-o sa traiesc cat lumea (Gica Petrescu)', color: '#e6b800' },
  { id: 30, title: 'Inimă nestatornică', file: 'Inima nestatornica (Doina Badea)', color: '#049fbb', lightText: true },
  { id: 31, title: 'Ți-ai făcut păcat cu mine', file: 'Ti-ai facut pacat cu mine (Simona Cassian)', color: '#fa7d34' },
  { id: 32, title: 'Ai și venit la mine, toamnă !...', file: 'Ai si venit la mine toamna (Mia Braia)', color: '#049fbb', lightText: true },
  { id: 33, title: 'Ce fată !', file: 'Ce fata (Dan Spataru)', color: '#c24354' },
  { id: 34, title: 'Alunița', file: 'Alunita (George Bunea)', color: '#e99809' },
  { id: 35, title: 'O mie de viori', file: 'O mie de viori (Luigi Ionescu)', color: '#a1d9bc' },
  { id: 36, title: 'Signorina Musica', file: 'Signorina Musica (George Bunea)', color: '#ad8964' },
  { id: 37, title: 'Te-am găsit și te voi păstra', file: 'Te-am gasit si te voi pastra (Luigi Ionescu)', color: '#e7c9a3' },
  { id: 38, title: 'Mon amour, mon amour', file: 'Mon amour, mon amour (Luminita Dobrescu)', color: '#e6b800' },
  { id: 39, title: 'La mare', file: 'La mare (Luigi Ionescu si Maria Ianoli)', color: '#049fbb', lightText: true },
  { id: 40, title: 'Nani-nani', file: 'Nani, nani (Stefan Banica)', color: '#c24354' },
  { id: 41, title: 'Visez cu ochii deschiși', file: 'Visez cu ochii deschisi (Luminita Cosmin)', color: '#3c6172', lightText: true },
  { id: 42, title: 'Te iubesc până la moarte...', file: 'Te iubesc pînă la moarte (Doina Badea)', color: '#79748d', lightText: true },
  { id: 43, title: 'Toată tinerețea mea ești tu', file: 'Toata tineretea mea esti tu (Margareta Paslaru)', color: '#b8b0a1' },
  { id: 44, title: 'Cine-mi ești, cine-mi ești', file: 'Cine-mi esti (Nicolae Nitescu si Surorile Kosak)', color: '#5e4721', lightText: true },
  { id: 45, title: 'Drăguț din partea ta', file: 'Dragut din partea ta (Marcela Rusu)', extension: 'm4a', color: '#948e8d' },
  { id: 46, title: 'Cine n-a iubit măcar o dată ?', file: 'Cine n-a iubit macar o data (Simona Cassian)', color: '#a1d9bc' },
  { id: 47, title: 'Mă mai iubești sau nu ?', file: 'Ma mai iubesti sau nu (Aida Moga)', color: '#772257', lightText: true },
  { id: 48, title: 'Îmi placi din ce în ce mai mult !...', file: 'Îmi placi din ce în ce mai mult (Jean Păunescu)', color: '#e7b900' },
  { id: 49, title: 'Femeie', file: 'Femeie (Luigi Ionescu)', color: '#b32d12' },
  { id: 50, title: 'Mai dă-mi un telefon', file: 'Mai da-mi un telefon (Stefan Banica)', color: '#c24354' },
  { id: 51, title: 'Mie-mi place să trăiesc', file: 'Mie-mi place sa traiesc (Gica Petrescu)', color: '#a1d9bb' },
  { id: 52, title: 'Embrasse-moi, baby !', file: 'Embrasse-moi, baby (Cristina Stamate)', extension: 'm4a', color: '#fb7e35' },
  { id: 53, title: 'Cum să fac să mi te scot din gând ?', file: 'Cum sa fac sa mi te scot din gand (Dorina Draghici)', color: '#ded5c3' },
  { id: 54, title: 'Nu-i nimic !...', file: 'Nu-i nimic (Stefan Banica)', color: '#948e8d' },
  { id: 55, title: 'Cine umblă prin vecini', file: 'Cine umbla prin vecini (Stefan Banica)', color: '#ff1703' },
  { id: 56, title: 'Lasă... (Lasă supărarea)', file: 'Lasa supararea (Gina Patrascu)', color: '#c2ca00' },
  { id: 57, title: 'Cine știe ?...', file: 'Cine stie (Dorina Draghici)', color: '#6f7967', lightText: true },
];
