import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';

export const Icon: FC<IconProps> = ({ icon, size }) => <FontAwesomeIcon icon={icon} size={size} />;

interface IconProps {
  icon: IconProp;
  size?: SizeProp;
}
