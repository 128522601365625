import React, { FC } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { MusicProvider } from './MusicProvider';
import { Bio } from './pages/Bio';
import { Foundation } from './pages/Foundation';
import { Music } from './pages/Music';
import { appTheme } from './style';
import { GlobalStyle } from './style/GlobalStyle';

export const App: FC<{}> = () => (
  <BrowserRouter>
    <GlobalStyle theme={appTheme} />
    <ThemeProvider theme={appTheme}>
      <MusicProvider>
        <Header />
        <Switch>
          <Route path="/" exact component={Music} />
          <Route path="/bio" exact component={Bio} />
          <Route path="/foundation" exact component={Foundation} />
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
        <Footer />
      </MusicProvider>
    </ThemeProvider>
  </BrowserRouter>
);
