import { createGlobalStyle } from 'styled-components';
import { AppTheme } from './index';

interface IGlobalStyle {
  theme: AppTheme;
}

export const GlobalStyle = createGlobalStyle<IGlobalStyle>`
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.font};
    font-family: ${({ theme }) => theme.fonts.primary}, sans-serif;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.sizes.md};
    text-align: justify;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
