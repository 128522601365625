import baseStyled, { ThemedStyledInterface } from 'styled-components';

export const appTheme = {
  colors: {
    background: '#0b0b0b',
    font: '#eee',
    fontGray: '#666',
    fontDark: '#111',
    fontTitle: '#049fbb',
  },
  durations: {
    fast: '200ms',
    regular: '300ms',
  },
  fonts: {
    primary: 'Roboto',
    title: 'Open Sans',
  },
  sizes: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',

    xsNum: 4,
    xlNum: 32,

    phone: '700px',
    tablet: '1200px',
  },
};

export type AppTheme = typeof appTheme;

export const styled = baseStyled as ThemedStyledInterface<AppTheme>;
