import { styled } from '../style';

export const TextPageContainer = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.sizes.md};

  @media (max-width: ${({ theme }) => theme.sizes.phone}) {
    flex-direction: column;
    padding: ${({ theme }) => theme.sizes.xs};
  }
`;

export const TextPageColumn = styled.div<{ grow?: boolean }>`
  flex-grow: ${({ grow = true }) => (grow ? 1 : 0)};
  flex-basis: 0;
  margin: 0 ${({ theme }) => theme.sizes.md};

  @media (max-width: ${({ theme }) => theme.sizes.phone}) {
    margin-bottom: ${({ theme }) => theme.sizes.md};
  }
`;

export const TextPageImg = styled.img<{ size?: number }>`
  max-width: ${({ size }) => (size ? `${size}px` : 'auto')};

  @media (max-width: ${({ theme }) => theme.sizes.phone}) {
    display: block;
    margin: 0 auto;
    margin-bottom: ${({ theme }) => theme.sizes.md};
  }
`;
