import React from 'react';
import { TextPageContainer, TextPageColumn, TextPageImg } from '../components/TextPage';
import { styled } from '../style';

const foundationImg = require('../img/foundation.jpeg');
const foundationLogoImg = require('../img/foundation_logo.png');
const facebookImg = require('../img/logo_facebook.svg');
const instagramImg = require('../img/logo_instagram.svg');
const youtubeImg = require('../img/logo_youtube.svg');

export const Foundation = () => {
  return (
    <TextPageContainer>
      <TextPageColumn grow={false}>
        <TextPageImg src={foundationImg.default} size={200} />
      </TextPageColumn>
      <TextPageColumn>
        <p>
          Talentul autentic, iubirea dezinteresata pentru muzica si arta in general, nobilitatea spiritului si modestia, subscrise unui ideal umanist
          l-au definit pe omul si compozitorul Henry Malineanu.
        </p>
        <p>
          Fundatia Henry Malineanu promoveaza valorile in care s-a format, a creat si a crezut si isi propune sa incurajeze setea de frumos prin
          muzica.
        </p>
        <p>
          Pentru a da contur acestor principii, Fundatia va interveni in toate domeniile artei, cu precadere in cel muzical, prin premii, burse,
          evenimente si publicatii, pentru a pastra vie memoria generozitatii, elegantei, umorului si profesionalismului acestui reper al culturii
          romanesti, demn model pentru generatiile viitoare.
        </p>
      </TextPageColumn>
      <TextPageColumn grow={false}>
        <FoundationContainer>
          <div>
            <p>
              <b>Fundația "Henry Mălineanu"</b>
            </p>
            <p>Str. Dr. Eugen Iosif Nr. 18, Sector 5, București</p>
            <p>
              CIF: 18307092
              <br />
              Cont: RO46INGB0001008196418910
              <br />
              Banca: ING Bank București
            </p>
            <p>
              Telefon: +4 0733 777242
              <br />
              Email: fundatia@henrymalineanu.ro
            </p>
          </div>
          <FoundationLogo src={foundationLogoImg.default} size={150} />
        </FoundationContainer>
        <SocialContainer>
          <a href="https://www.facebook.com/henrymalineanu" target="_blank">
            <img src={facebookImg.default} />
          </a>
          <a href="https://www.instagram.com/henrymalineanu" target="_blank">
            <img src={instagramImg.default} />
          </a>
          <a href="https://www.youtube.com/playlist?list=PLrgVVovV9Oby-R_Dr4qZG_tm5eKF_g2TM" target="_blank">
            <img src={youtubeImg.default} />
          </a>
        </SocialContainer>
      </TextPageColumn>
    </TextPageContainer>
  );
};

const SOCIAL_LINK_SIZE = 60;

const FoundationContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.fontTitle};

  p {
    white-space: nowrap;
  }

  @media (max-width: ${({ theme }) => theme.sizes.phone}) {
    flex-direction: column-reverse;
  }
`;

const FoundationLogo = styled(TextPageImg)`
  @media (max-width: ${({ theme }) => theme.sizes.phone}) {
    margin-top: ${({ theme }) => theme.sizes.md};
  }
`;

const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.sizes.md};

  img {
    width: ${SOCIAL_LINK_SIZE}px;
  }

  a:not(:first-child):not(:last-child) {
    margin: 0 ${({ theme }) => theme.sizes.sm};
  }

  @media (max-width: ${({ theme }) => theme.sizes.phone}) {
    justify-content: center;
  }
`;
