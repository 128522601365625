import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faPause } from '@fortawesome/free-solid-svg-icons/faPause';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import React from 'react';
import { useMusic } from '../MusicProvider';
import { styled } from '../style';
import { Icon } from './Icon';

export const MusicPlayer = () => {
  const { playing, togglePlay, prev, next, current } = useMusic();

  return (
    <PlayerContainer color={current.color}>
      <PlayerButton lightText={current.lightText} onClick={prev}>
        <Icon icon={faChevronLeft} size="xs" />
      </PlayerButton>
      <PlayerButton lightText={current.lightText} onClick={togglePlay}>
        {playing ? <Icon icon={faPause} size="xs" /> : <Icon icon={faPlay} size="xs" />}
      </PlayerButton>
      <PlayerButton lightText={current.lightText} onClick={next}>
        <Icon icon={faChevronRight} size="xs" />
      </PlayerButton>
      <PlayerTitle title={current.title}>{current.title || '...'}</PlayerTitle>
    </PlayerContainer>
  );
};

export const PLAYER_HEIGHT = 30;
export const PLAYER_HEIGHT_MOBILE = 40;

const PlayerContainer = styled.div<{ color?: string }>`
  display: inline-flex;
  height: ${PLAYER_HEIGHT}px;
  padding: ${({ theme }) => theme.sizes.xs};
  text-align: center;
  background: ${({ color }) => (color ? color : '#222')};
  cursor: default;
  user-select: none;
  transition: background ${({ theme }) => theme.durations.regular};

  @media (max-width: ${({ theme }) => theme.sizes.phone}) {
    width: 100%;
    height: ${PLAYER_HEIGHT_MOBILE}px;
    box-shadow: 0 -5px 8px rgba(0, 0, 0, 0.2);
  }
`;

const PlayerTitle = styled.div`
  width: 150px;
  padding: ${({ theme }) => theme.sizes.xs};
  margin-left: ${({ theme }) => theme.sizes.xs};
  font-size: 0.8rem;
  text-align: left;
  background: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.sizes.phone}) {
    flex-grow: 1;
    width: auto;
    padding: ${({ theme }) => theme.sizes.sm};
  }
`;

const PlayerButton = styled.button<{ lightText?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => PLAYER_HEIGHT - 2 * theme.sizes.xsNum}px;
  width: ${({ theme }) => PLAYER_HEIGHT - 2 * theme.sizes.xsNum}px;
  background: transparent;
  color: ${({ lightText, theme }) => (lightText ? theme.colors.font : theme.colors.background)};
  border: none;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: ${({ theme }) => theme.sizes.xs};
  }

  @media (max-width: ${({ theme }) => theme.sizes.phone}) {
    height: ${({ theme }) => PLAYER_HEIGHT_MOBILE - 2 * theme.sizes.xsNum}px;
    width: ${({ theme }) => PLAYER_HEIGHT_MOBILE - 2 * theme.sizes.xsNum}px;
  }
`;
