import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { styled } from '../style';
import { MusicPlayer } from './MusicPlayer';
import { appTheme } from '../style';

export const Header = () => {
  const activeStyle = {
    color: appTheme.colors.fontTitle,
    borderColor: appTheme.colors.fontTitle,
  };

  return (
    <HeaderContainer>
      <HeaderElement align="left">
        <Link to="/">
          <HeaderTitle>
            <span>Henry Malineanu</span>
            <HeaderSubTitle>— 100 de ani, 100 de melodii —</HeaderSubTitle>
          </HeaderTitle>
        </Link>
      </HeaderElement>
      <HeaderElement grow>
        <nav>
          <StyledNavLink to="/" activeStyle={activeStyle} exact>
            Muzică
          </StyledNavLink>
          <StyledNavLink to="/bio" activeStyle={activeStyle} exact>
            Biografie
          </StyledNavLink>
          <StyledNavLink to="/foundation" activeStyle={activeStyle} exact>
            Fundația
          </StyledNavLink>
        </nav>
      </HeaderElement>
      <HeaderElement>
        <MusicPlayerWrapper>
          <MusicPlayer />
        </MusicPlayerWrapper>
      </HeaderElement>
    </HeaderContainer>
  );
};

export const HEADER_HEIGHT = 150;

const HeaderContainer = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.sizes.xl} 0;
  height: ${HEADER_HEIGHT}px;

  @media (max-width: ${({ theme }) => theme.sizes.phone}) {
    flex-direction: column;
    justify-content: space-around;
  }
`;

const HeaderElement = styled.div<{ align?: string; grow?: boolean }>`
  flex-grow: ${({ grow }) => (grow ? 1 : 0)};
  text-align: ${({ align }) => align || 'right'};
  padding: 0 ${({ theme }) => theme.sizes.lg};

  @media (max-width: ${({ theme }) => theme.sizes.phone}) {
    flex-grow: 0;
  }
`;

const HeaderTitle = styled.h1`
  display: flex;
  flex-direction: column;
  margin: 0;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.fontTitle};
  font-family: ${({ theme }) => theme.fonts.title}, sans-serif;
  font-size: 2.5rem;
  letter-spacing: 0.4rem;
  font-weight: 300;
  text-align: center;
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.sizes.phone}) {
    margin-bottom: ${({ theme }) => theme.sizes.lg};
    font-size: 1.5rem;
  }
`;

const HeaderSubTitle = styled.span`
  color: ${({ theme }) => theme.colors.font};
  font-size: 1.1rem;
  letter-spacing: 0.3rem;
  font-weight: 800;

  @media (max-width: ${({ theme }) => theme.sizes.phone}) {
    font-size: 0.8rem;
  }
`;

const StyledNavLink = styled(NavLink)`
  margin: 0 ${({ theme }) => theme.sizes.sm};
  padding-bottom: ${({ theme }) => theme.sizes.xs};
  font-family: ${({ theme }) => theme.fonts.title}, sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  border-bottom: 1px solid transparent;
  transition: ${({ theme }) => theme.durations.regular};

  &:hover {
    border-color: ${({ theme }) => theme.colors.font};
  }
`;

const MusicPlayerWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.sizes.phone}) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
`;
