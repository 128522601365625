import React, { useContext, useEffect, useRef, useState } from 'react';
import { SongData, SONGS } from './data/songs';

type MusicContextType = {
  playing: boolean;
  togglePlay: () => void;
  prev: () => void;
  next: () => void;
  setSong: (song: SongData) => void;
  current?: SongData;
};

const MusicContext = React.createContext<MusicContextType>({
  playing: false,
  togglePlay: () => {},
  prev: () => {},
  next: () => {},
  setSong: () => {},
});

const audio = new Audio();
const SONGS_PATH = '/assets/music';

export const MusicProvider: React.FC = ({ children }) => {
  const [index, setIndex] = useState(0);
  const [playing, setPlaying] = useState(false);

  const isInitialMount = useRef(true);

  useEffect(() => {
    const song = SONGS[index];
    audio.src = `${SONGS_PATH}/${song.file}.${song.extension || 'mp3'}`;
    audio.onended = next;

    // don't play on first mount
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      audio.play();
      setPlaying(true);
    }
  }, [index]);

  const play = () => {
    audio.play();
    setPlaying(true);
  };
  const pause = () => {
    audio.pause();
    setPlaying(false);
  };

  const togglePlay = () => (playing ? pause() : play());
  const prev = () => setIndex(index > 0 ? index - 1 : SONGS.length - 1);
  const next = () => setIndex(index < SONGS.length - 1 ? index + 1 : 0);

  const setSong = (newSong: SongData) => {
    const songIndex = SONGS.findIndex(s => s.id === newSong.id);

    if (songIndex === -1) {
      return;
    } else if (index === songIndex) {
      play();
    } else {
      setIndex(songIndex);
    }
  };

  return <MusicContext.Provider value={{ playing, togglePlay, prev, next, setSong, current: SONGS[index] }}>{children}</MusicContext.Provider>;
};

export const useMusic = () => {
  return useContext(MusicContext);
};
