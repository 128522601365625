import React, { useState } from 'react';
import { Popup } from '../../components/Popup';
import { SONGS } from '../../data/songs';
import { getCookie, setCookie } from '../../helpers/cookies';
import { styled } from '../../style';
import { Song } from './Song';

const TUTORIAL_COOKIE = 'music_tutorial';

export const Music = () => {
  const [showPopup, setShowPopup] = useState(true);

  const showPopupCookie = getCookie(TUTORIAL_COOKIE);

  return (
    <div>
      <MusicList>
        {SONGS.map(song => (
          <Song key={song.id} song={song} />
        ))}
      </MusicList>
      {!showPopupCookie && showPopup && (
        <Popup
          hide={() => {
            setCookie(TUTORIAL_COOKIE, 'true', 30);
            setShowPopup(false);
          }}
        >
          <p>Click pe orice melodie și ascultă !</p>
          <p>Click on any song to start the music!</p>
        </Popup>
      )}
    </div>
  );
};

const MusicList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;
