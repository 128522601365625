import React from 'react';
import { TextPageContainer, TextPageColumn, TextPageImg } from '../components/TextPage';

const bioImg = require('../img/bio.jpg');

export const Bio = () => {
  return (
    <TextPageContainer>
      <TextPageColumn grow={false}>
        <TextPageImg src={bioImg.default} size={200} />
      </TextPageColumn>
      <TextPageColumn>
        <p>
          Născut în București în 1920 studiază în paralel cu liceul la Conservatorul Regal de Muzică sub îndrumarea marilor profesori Cecilia
          Nitzulescu-Lupu (vioară), Ion Nonna-Otescu (armonie), Mihail Andricu (muzică de cameră)... și înființează la numai 16 ani propria formație
          de jazz numită întâi "Tango", apoi "Metropol".
        </p>
        <p>
          Un prim contact cu lumea scenei, în trupa Teatrului "Nou" din Calea Vacarești îi permite deja colaborarea cu mari nume ale vremii precum
          compozitorul Max Halm, regizorul Iacob Sternberg, actorii Sidi Tal, Stroe și Vasilache...
        </p>
        <p>
          Repede remarcat de marele compozitor, dirijor și om de teatru Ion Vasilescu, obține, la 18 ani postul de pianist în orchestra teatrului
          Alhambra și se produce pentru prima oară în revista "De la munte la mare" pe scena grădinii "Colos". Anul 1938 îi aduce și prima
          înregistrare a unor melodii proprii pe discuri Columbia și Electrecord, precum și admiterea ca membru al Societății Compozitorilor Români în
          urma primelor succese muzicale: cântecul "Întoarce-te curând", scris pentru Lya Crăciunescu e reluat de Jean Moscopol ca și de tânărul Gică
          Petrescu. Același fidel interpret lansează "Ce bine ne-nțelegem noi doi !"
        </p>
        <p>
          În aceeași perioadă îl cunoaște pe Eugen Mirea, textier și autor de librete deja afirmat, cu care va colabora, într-o strânsă prietenie,
          aproape 40 de ani...
        </p>
        <p>
          Obligat să părăseasca oficial lumina rampei din cauza legilor legionare, va continua să compună si să scrie sub ocrotirea (si pseudonimul)
          unor mari personalitați artistice și literare precum Ion Vasilescu și Tudor Mușatescu multe cântece de neuitat (de exemplu "Astă seară mă
          fac "praf", reluat mult mai târziu de Ștefan Bănică...) în revistele teatrelor Alhambra și Gioconda.
        </p>
        <p>
          După înființarea în 1941 a teatrului evreiesc "Baraseum" creează împreună cu Eugen Mirea revistele de mare succes "Primăvara în Do major",
          "Dai un ban dar face", "Baraseum '42" și "Allo Baraseum" care lansează șlagăre nepieritoare precum "...că doar n-o să trăiesc cât lumea !"
          sau "Pe cine-aș fi iubit, dacă n-ai fi fost tu ?..."
        </p>
      </TextPageColumn>
      <TextPageColumn>
        <p>
          După 1944 reintegrează ca dirijor și co-director muzical teatrul lui Ion Vasilescu și participă la creația (de această dată "pe afiș" !)
          unor reviste iubite de public precum "Aliații Giocondei", "Bal la Gioconda", "Gioconda Palace", "Gioconda la Savoy", etc. în care șlagarele
          se succed: "Primul nostru tango", "Mai dă-mi un telefon", "Fericirea mea e-n mâinile tale", pe când la Teatrul Boema i se lansează "Ce cauți
          tu în viața mea" (mai târziu interzis de regimul comunist...), "Adevărata mea dragoste", "Valsul nostru"...
        </p>
        <p>
          Este "fondator" împreună cu Jack Fulga, Puiu Maximilian și Maria Tănase al Teatrului de Estradă, (ulterior Teatrul de Revistă), al cărui
          director muzical devine între 1950-1958.
        </p>
        <p>
          Printre spectacolele cele mai reușite se numără revistele "Între noi femeile", "Dragoste pe puncte", "Cer cuvântul", "Concertul
          popoarelor"... Pentru acesta creeaza "Dragi mi-s cântecele mele", un imn dedicat muzicii românești autentice, cântat de nemuritoarea Maria
          Tănase.
        </p>
        <p>Alte șlagare îi aduc aprecierea unui public din ce în ce mai larg: "Hop și-așa", "Garofița"...</p>
        <p>
          Anii 60 văd nașterea noii generații de interpreți ai muzicii ușoare românești, pentru care va compune cântece precum "Nici-o dragoste nu e
          ca a noastră" (Margareta Pâslaru), "Nimeni" (Doina Badea), "Dacă n-oi trăi acum" (Dan Spătaru) șamd. În același timp, melodii ca "Am început
          să-mbătrânesc", "Aș vrea iar anii tinereții" și "Nu se poate" il consacră ca unul din cei mai apreciați compozitori de romanțe.
        </p>
        <p>
          La începutul anilor 70, Henry Mălineanu creeaza în colaborare cu Eugen Mirea la Teatrul Nottara comediile muzicale "Lady X", "Cele două
          orfeline" și mai ales "Bună seara domnule Wilde", vârf incontestabil al genului în România, plebiscitată de public, adaptată și reluată de
          numeroase teatre din țară și străinătate.
        </p>
        <p>
          Va compune muzica de scenă pentru piesele "Micul infern", "Căruța cu paiate", "Comedie de modă veche" (care se va juca timp de 15 ani !) și
          multe altele, muzică de film ("Marinică", "Pantoful Cenușăresei" etc), operetă, teatru pentru copii ("Mica sirenă")...
        </p>
        <p>
          Artist Emerit (1962), Laureat al Premiului de Stat (1953), a obținut premiile Festivalurilor de muzică ușoară Mamaia (1963, 1964, 1969,
          1971) și "Crizantema de aur" Târgoviște (1980) precum și Premiul Uniunii Compozitorilor din Romănia (1971, 1977, 1978, 1991 pentru întreaga
          activitate muzicală).
        </p>
        <p>Se stinge din viață la București, în noiembrie 2000.</p>
      </TextPageColumn>
    </TextPageContainer>
  );
};
