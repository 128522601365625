import React, { FC, useEffect } from 'react';
import { styled } from '../style';
import { HEADER_HEIGHT } from './Header';

interface PopupProps {
  hide: () => void;
}

export const Popup: FC<PopupProps> = ({ children, hide }) => {
  useEffect(() => {
    const initialValue = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = initialValue;
    };
  }, []);

  return (
    <Layer onClick={hide}>
      <PopupContainer>
        <PopupContent>{children}</PopupContent>
        <div>
          <PopupButton>OK</PopupButton>
        </div>
      </PopupContainer>
    </Layer>
  );
};

const Layer = styled.div`
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.sizes.md};
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;

const PopupContainer = styled.div`
  color: ${({ theme }) => theme.colors.fontDark};
  font-size: 1.1rem;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
`;

const PopupContent = styled.div`
  padding: ${({ theme }) => theme.sizes.xl};
  background: rgba(255, 255, 255, 0.9);

  p {
    text-align: center;
  }
`;

const PopupButton = styled.button`
  width: 100%;
  padding: ${({ theme }) => theme.sizes.md} ${({ theme }) => theme.sizes.lg};
  background: ${({ theme }) => theme.colors.fontTitle};
  border: none;
  color: ${({ theme }) => theme.colors.font};
  font-weight: bold;
  cursor: pointer;
`;
