import React, { FC } from 'react';
import { SongData } from '../../data/songs';
import { useMusic } from '../../MusicProvider';
import { styled } from '../../style';

export const Song: FC<SongProps> = ({ song }) => {
  const { setSong } = useMusic();

  const img = require(`../../img/music/${song.id}.png`);

  return (
    <SongContainer key={song.id} song={song} onClick={() => setSong(song)}>
      <SongImg img={img.default} />
    </SongContainer>
  );
};

interface SongProps {
  song: SongData;
}

const SongContainer = styled.li<{ song: SongData }>`
  position: relative;
  height: 100px;
  width: calc(100% / 6);
  background-color: ${({ song }) => song.color};
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    width: calc(100% / 4);
  }

  @media (max-width: ${({ theme }) => theme.sizes.phone}) {
    width: calc(100% / 2);
  }
`;

const SongImg = styled.div<{ img: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${({ img }) => img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: ${({ theme }) => theme.durations.regular};

  &:hover {
    transform: scale(1.1);
    z-index: 1;
  }
`;
